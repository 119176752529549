import React, {
  useState,
  useEffect,
  useContext,
  useImperativeHandle,
  forwardRef,
  useCallback
} from "react";
import { useTranslation } from "react-i18next";
import { AgGridReact } from "ag-grid-react";
import { Context } from "../App";
import CellHeader from "../components/CellHeader";
import { greyCellStyle, gridStyles, preWrapWordBreak } from "../constants/styles";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { MAX_ATTR_DESCRIPTION_CHARS, MAX_ATTR_LABEL_CHARS } from "../constants/constants";

const textareaStyle = {
  width: "98%",
  height: "100%",
  resize: "none",
  outline: "none",
  fontFamily:
    // eslint-disable-next-line quotes
    '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
  fontSize: "12px"
};

const TextareaCellEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },

    isCancelBeforeStart() {
      return false;
    },

    isCancelAfterEnd() {
      return false;
    }
  }));

  return (
    <textarea
      autoFocus
      maxLength={MAX_ATTR_DESCRIPTION_CHARS}
      style={textareaStyle}
      value={value}
      onChange={(event) => setValue(event.target.value)}
    />
  );
});

export default function LanGrid({ gridRef, currentLanguage, setLoading }) {
  const { t } = useTranslation();
  const {
    attributesList,
    lanAttributeRowData,
    setLanAttributeRowData,
    attributeRowData,
    savedEntryCodes,
    attributesWithLists,
    languages
  } = useContext(Context);

  // Sets Language Dependent Attribute row data
  useEffect(() => {
    const newLanAttributeRowData = JSON.parse(JSON.stringify(lanAttributeRowData));
    languages.forEach((language) => {
      if (!newLanAttributeRowData[language]) {
        const newLanguageList = [];
        attributesList.forEach((item) => {
          let listDisplay = attributeRowData.find((obj) => obj.Attribute === item).List;
          if (!listDisplay) {
            listDisplay = "Not a List";
          } else {
            const listDisplayArray = [];
            savedEntryCodes[item].forEach((i) => {
              listDisplayArray.push(i[language]);
            });
            const listDisplayString = listDisplayArray.join(" | ");
            listDisplay = listDisplayString;
          }
          newLanguageList.push({
            Attribute: item,
            Label: "",
            Description: "",
            List: listDisplay
          });
        });
        newLanAttributeRowData[language] = newLanguageList;
      } else {
        const newLanguageList = [];
        attributeRowData.forEach((item) => {
          let newLabel = "";
          let newDescription = "";
          if (
            newLanAttributeRowData[language].find((i) => i.Attribute === item.Attribute)
          ) {
            newLabel = newLanAttributeRowData[language].find(
              (i) => i.Attribute === item.Attribute
            ).Label;
          }
          if (
            newLanAttributeRowData[language].find((i) => i.Attribute === item.Attribute)
          ) {
            newDescription = newLanAttributeRowData[language].find(
              (i) => i.Attribute === item.Attribute
            ).Description;
          }
          let listDisplay = item.List;
          if (!listDisplay) {
            listDisplay = "Not a List";
          } else {
            const listDisplayArray = [];
            savedEntryCodes[item.Attribute].forEach((i) => {
              listDisplayArray.push(i[language]);
            });
            const listDisplayString = listDisplayArray.join(" | ");
            listDisplay = listDisplayString;
          }

          const newObj = {
            Attribute: item.Attribute,
            Label: newLabel,
            Description: newDescription,
            List: listDisplay
          };
          newLanguageList.push(newObj);
        });
        newLanAttributeRowData[language] = newLanguageList;
      }
    });
    setLanAttributeRowData(newLanAttributeRowData);
  }, [languages, savedEntryCodes, attributeRowData]);

  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    setColumnDefs([
      {
        field: "Attribute",
        editable: false,
        width: 120,
        autoHeight: true,
        cellStyle: () => preWrapWordBreak,
        headerComponent: CellHeader,
        headerComponentParams: {
          headerText: t("Attribute"),
          helpText: t("This is the name for the attribute and, for example...")
        }
      },
      {
        field: "Label",
        editable: true,
        width: 250,
        autoHeight: true,
        cellStyle: () => preWrapWordBreak,
        headerComponent: CellHeader,
        headerComponentParams: {
          headerText: t("Label"),
          constraint: t("max label chars", { maxLabelChars: MAX_ATTR_LABEL_CHARS }),
          helpText: t("This is the language specific label for an attribute")
        },
        cellEditorParams: {
          maxLength: MAX_ATTR_LABEL_CHARS
        }
      },
      {
        field: "Description",
        editable: true,
        width: 260,
        cellEditor: TextareaCellEditor,
        autoHeight: true,
        cellStyle: () => preWrapWordBreak,
        headerComponent: CellHeader,
        headerComponentParams: {
          headerText: t("Description"),
          constraint: t("max description chars", {
            maxDescriptionChars: MAX_ATTR_DESCRIPTION_CHARS
          }),
          helpText: t("This is a language specific description of the attribute...")
        }
      },
      {
        field: "List",
        headerName: t("List"),
        editable: false,
        width: 258,
        autoHeight: true,
        cellStyle: (params) => {
          if (attributesWithLists.includes(params.data.Attribute)) {
            return { whiteSpace: "pre-wrap", wordBreak: "break-word" };
          }
          return greyCellStyle;
        }
      }
    ]);
  }, [attributesList, t]);

  const onCellKeyDown = (e) => {
    const keyPressed = e.event.code;
    const isLabelRow = e.column.colId === "Label";

    if (keyPressed === "Enter" && isLabelRow) {
      const { api } = e;
      const editingRowIndex = e.rowIndex;
      api.setFocusedCell(editingRowIndex + 1, "Label");
    }
  };

  const onGridReady = useCallback(() => {
    setLoading(false);
  }, []);

  return (
    <div className="ag-theme-balham" style={{ width: 890 }}>
      <style>{gridStyles}</style>
      <AgGridReact
        ref={gridRef}
        rowData={lanAttributeRowData[currentLanguage]}
        columnDefs={columnDefs}
        onCellKeyDown={onCellKeyDown}
        domLayout="autoHeight"
        onGridReady={onGridReady}
      />
    </div>
  );
}
