export const languageCodesObject = {
  afar: "aa",
  abkhazian: "ab",
  afrikaans: "af",
  akan: "ak",
  albanian: "sq",
  amharic: "am",
  arabic: "ar",
  aragonese: "an",
  armenian: "hy",
  assamese: "as",
  avaric: "av",
  avestan: "ae",
  aymara: "ay",
  azerbaijani: "az",
  bambara: "bm",
  bashkir: "ba",
  basque: "eu",
  belarusian: "be",
  bengali: "bn",
  bislama: "bi",
  bosnian: "bs",
  breton: "br",
  bulgarian: "bg",
  burmese: "my",
  catalan: "ca",
  valencian: "ca",
  chamorro: "ch",
  chechen: "ce",
  chichewa: "ny",
  chewa: "ny",
  nyanja: "ny",
  chinese: "zh",
  mandarin: "zh",
  "church slavonic": "cu",
  "old slavonic": "cu",
  "old church slavonic": "cu",
  slavonic: "cu",
  chuvash: "cv",
  cornish: "kw",
  corsican: "co",
  cree: "cr",
  croatian: "hr",
  czech: "cs",
  danish: "da",
  divehi: "dv",
  dhivehi: "dv",
  maldivian: "dv",
  dutch: "nl",
  flemish: "nl",
  dzongkha: "dz",
  english: "en",
  esperanto: "eo",
  estonian: "et",
  ewe: "ee",
  faroese: "fo",
  fijian: "fj",
  finnish: "fi",
  french: "fr",
  "western frisian": "fy",
  frisian: "fy",
  fulah: "ff",
  gaelic: "gd",
  "scottish gaelic": "gd",
  galician: "gl",
  ganda: "lg",
  georgian: "ka",
  german: "de",
  greek: "el",
  kalaallisut: "kl",
  greenlandic: "kl",
  guarani: "gn",
  gujarati: "gu",
  haitian: "ht",
  "haitian creole": "ht",
  creole: "ht",
  hausa: "ha",
  hebrew: "he",
  herero: "hz",
  hindi: "hi",
  "hiri motu": "ho",
  hungarian: "hu",
  icelandic: "is",
  ido: "io",
  igbo: "ig",
  indonesian: "id",
  interlingua: "ia",
  "international auxiliary language association": "ia",
  interlingue: "ie",
  occidental: "ie",
  inuktitut: "iu",
  inupiaq: "ik",
  irish: "ga",
  italian: "it",
  japanese: "ja",
  javanese: "jv",
  kannada: "kn",
  kanuri: "kr",
  kashmiri: "ks",
  kazakh: "kk",
  "central khmer": "km",
  khmer: "km",
  kikuyu: "ko",
  gikuyu: "ki",
  kinyarwanda: "rw",
  kirghiz: "ky",
  kyrgyz: "ky",
  komi: "kv",
  kongo: "kg",
  korean: "ko",
  kuanyama: "kj",
  kurdish: "ku",
  lao: "lo",
  latin: "la",
  latvian: "lv",
  limburgan: "li",
  limburger: "li",
  limburgish: "li",
  lingala: "ln",
  lithuanian: "lt",
  "luba-katanga": "lu",
  "luba katanga": "lu",
  luxembourgish: "lb",
  letzeburgesch: "lb",
  macedonian: "mk",
  malagasy: "mg",
  malay: "ms",
  malayalam: "ml",
  maltese: "mt",
  manx: "gv",
  maori: "mi",
  marathi: "mr",
  marshallese: "mh",
  mongolian: "mn",
  nauru: "na",
  navajo: "nv",
  navaho: "nv",
  "north ndebele": "nd",
  "south ndebele": "nr",
  ndonga: "ng",
  nepali: "ne",
  norwegian: "no",
  "norwegian bokmål": "nb",
  bokmål: "nb",
  "norwegian bokmal": "nb",
  bokmal: "nb",
  "norwegian nynorsk": "nn",
  nynorsk: "nn",
  "sichuan yi": "ii",
  nuosu: "ii",
  occitan: "oc",
  ojibway: "oj",
  oriya: "or",
  oromo: "om",
  ossetian: "os",
  ossetic: "os",
  pali: "pi",
  pashto: "ps",
  pushto: "ps",
  persian: "fa",
  polish: "pl",
  portuguese: "pt",
  punjabi: "pa",
  panjabi: "pa",
  quechua: "qu",
  romanian: "ro",
  moldavian: "ro",
  moldovan: "ro",
  romansh: "rm",
  rundi: "rn",
  russian: "ru",
  "northern sami": "se",
  sami: "se",
  samoan: "sm",
  sango: "sg",
  sanskrit: "sa",
  sardinian: "sc",
  serbian: "sr",
  shona: "sn",
  sindhi: "sd",
  sinhala: "si",
  sinhalese: "si",
  slovak: "sk",
  slovenian: "sl",
  somali: "so",
  "southern sotho": "st",
  sotho: "st",
  spanish: "es",
  castilian: "es",
  sundanese: "su",
  swahili: "sw",
  swati: "ss",
  swedish: "sv",
  tagalog: "tl",
  tahitian: "ty",
  tajik: "tg",
  tamil: "ta",
  tatar: "tt",
  telugu: "te",
  thai: "th",
  tibetan: "bo",
  tigrinya: "ti",
  tonga: "to",
  "tonga islands": "to",
  tsonga: "ts",
  tswana: "tn",
  turkish: "tr",
  turkmen: "tk",
  twi: "tw",
  uighur: "ug",
  uyghur: "ug",
  ukrainian: "uk",
  urdu: "ur",
  uzbek: "uz",
  venda: "ve",
  vietnamese: "vi",
  volapük: "vo",
  volapuk: "vo",
  walloon: "wa",
  welsh: "cy",
  wolof: "wo",
  xhosa: "xh",
  yiddish: "yi",
  yoruba: "yo",
  zhuang: "za",
  chuang: "za",
  zulu: "zu",
};

export const codesToLanguages = {
  "en": "English",
  "fr": "French",
  "es": "Spanish",
  "zh": "Mandarin",
  "de": "German",
  "ja": "Japanese",
  "it": "Italian",
  "aa": "afar",
  "ab": "abkhazian",
  "af": "afrikaans",
  "ak": "akan",
  "sq": "albanian",
  "am": "amharic",
  "ar": "arabic",
  "an": "aragonese",
  "hy": "armenian",
  "as": "assamese",
  "av": "avaric",
  "ae": "avestan",
  "ay": "aymara",
  "az": "azerbaijani",
  "bm": "bambara",
  "ba": "bashkir",
  "eu": "basque",
  "be": "belarusian",
  "bn": "bengali",
  "bi": "bislama",
  "bs": "bosnian",
  "br": "breton",
  "bg": "bulgarian",
  "my": "burmese",
  "ca": "catalan",
  "ch": "chamorro",
  "ce": "chechen",
  "ny": "nyanja",
  "cu": "slavonic",
  "cv": "chuvash",
  "kw": "cornish",
  "co": "corsican",
  "cr": "cree",
  "hr": "croatian",
  "cs": "czech",
  "da": "danish",
  "dv": "maldivian",
  "nl": "flemish",
  "dz": "dzongkha",
  "eo": "esperanto",
  "et": "estonian",
  "ee": "ewe",
  "fo": "faroese",
  "fj": "fijian",
  "fi": "finnish",
  "fy": "frisian",
  "ff": "fulah",
  "gd": "gaelic",
  "gl": "galician",
  "lg": "ganda",
  "ka": "georgian",
  "el": "greek",
  "kl": "greenlandic",
  "gn": "guarani",
  "gu": "gujarati",
  "ht": "haitian",
  "ha": "hausa",
  "he": "hebrew",
  "hz": "herero",
  "hi": "hindi",
  "ho": "hiri motu",
  "hu": "hungarian",
  "is": "icelandic",
  "io": "ido",
  "ig": "igbo",
  "id": "indonesian",
  "ia": "international auxiliary language association", // "ia" for "interlingua" and "international auxiliary language association"
  "ie": "occidental", // "ie" for "interlingue" and "occidental"
  "iu": "inuktitut",
  "ik": "inupiaq",
  "ga": "irish",
  "jv": "javanese",
  "kn": "kannada",
  "kr": "kanuri",
  "ks": "kashmiri",
  "kk": "kazakh",
  "km": "khmer",
  "ko": "korean",
  "ki": "gikuyu",
  "rw": "kinyarwanda",
  "ky": "kyrgyz",
  "kv": "komi",
  "kg": "kongo",
  "kj": "kuanyama",
  "ku": "kurdish",
  "lo": "lao",
  "la": "latin",
  "lv": "latvian",
  "li": "limburgish",
  "ln": "lingala",
  "lt": "lithuanian",
  "lu": "luba katanga",
  "lb": "letzeburgesch",
  "mk": "macedonian",
  "mg": "malagasy",
  "ms": "malay",
  "ml": "malayalam",
  "mt": "maltese",
  "gv": "manx",
  "mi": "maori",
  "mr": "marathi",
  "mh": "marshallese",
  "mn": "mongolian",
  "na": "nauru",
  "nv": "navaho",
  "nd": "north ndebele",
  "nr": "south ndebele",
  "ng": "ndonga",
  "ne": "nepali",
  "no": "norwegian",
  "nb": "bokmal",
  "nn": "nynorsk",
  "ii": "nuosu",
  "oc": "occitan",
  "oj": "ojibway",
  "or": "oriya",
  "om": "oromo",
  "os": "ossetic",
  "pi": "pali",
};

export const allLanguagesWithCodesArray = Object.keys(languageCodesObject);

// ISO 639-2 three-letter language codes (Alpha 3 codes)
export const languageNameToAlpha3Codes = {
  afrikaans: "afr",
  akan: "aka",
  amharic: "amh",
  arabic: "ara",
  assamese: "asm",
  azerbaijani: "aze",
  bambara: "bam",
  belarusian: "bel",
  bengali: "ben",
  bislama: "bis",
  bosnian: "bos",
  breton: "bre",
  bulgarian: "bul",
  catalan: "cat",
  czech: "ces",
  chamorro: "cha",
  chechen: "che",
  chuvash: "chv",
  cornish: "cor",
  corsican: "cos",
  welsh: "cym",
  danish: "dan",
  german: "deu",
  dhivehi: "div",
  dzongkha: "dzo",
  greek: "ell",
  english: "eng",
  estonian: "est",
  ewe: "ewe",
  persian: "fas",
  fijian: "fij",
  finnish: "fin",
  french: "fra",
  fulah: "ful",
  irish: "gle",
  galician: "glg",
  gujarati: "guj",
  hausa: "hau",
  hebrew: "heb",
  hindi: "hin",
  "hiri motu": "hmo",
  croatian: "hrv",
  hungarian: "hun",
  igbo: "ibo",
  indonesian: "ind",
  icelandic: "isl",
  italian: "ita",
  javanese: "jav",
  japanese: "jpn",
  kannada: "kan",
  kashmiri: "kas",
  kazakh: "kaz",
  khmer: "khm",
  kinyarwanda: "kin",
  kyrgyz: "kir",
  komi: "kom",
  kongo: "kon",
  korean: "kor",
  kurdish: "kur",
  lao: "lao",
  latin: "lat",
  latvian: "lav",
  lingala: "lin",
  lithuanian: "lit",
  luxembourgish: "ltz",
  "luba-katanga": "lub",
  ganda: "lug",
  malayalam: "mal",
  marathi: "mar",
  malay: "msa",
  maltese: "mlt",
  māori: "mri",
  "maori": "mri",
  burmese: "mya",
  nauru: "nau",
  nepali: "nep",
  dutch: "nld",
  "norwegian nynorsk": "nno",
  "norwegian bokmål": "nob",
  "northern sotho": "nso",
  occitan: "oci",
  oromo: "orm",
  punjabi: "pan",
  polish: "pol",
  portuguese: "por",
  pashto: "pus",
  quechua: "que",
  romanian: "ron",
  russian: "rus",
  sanskrit: "san",
  sinhala: "sin",
  slovak: "slk",
  slovenian: "slv",
  somali: "som",
  "southern sotho": "sot",
  spanish: "spa",
  albanian: "sqi",
  serbian: "srp",
  swati: "ssw",
  swahili: "swa",
  swedish: "swe",
  tamil: "tam",
  telugu: "tel",
  thai: "tha",
  tigrinya: "tir",
  turkish: "tur",
  ukrainian: "ukr",
  urdu: "urd",
  uzbek: "uzb",
  vietnamese: "vie",
  xhosa: "xho",
  yoruba: "yor",
  chinese: "zho",
  mandarin: "zho",
  zulu: "zul"
};

export const toThreeLetterCode = (twoLetterCode) => {
  const languageName = codesToLanguages[twoLetterCode];
  return languageNameToAlpha3Codes[languageName];
}
